import PropTypes from 'prop-types'
import React from 'react'

// Components
import SiteNav from 'components/navbar'
import Layout from 'components/layout'
import Testimonials from 'components/testimonials'
import Clients from 'components/clients'
import Customers from 'components/customers'
import Objectives from 'components/objectives'
import AnnaQuote from 'components/annaQuote'

// Page Components
import StrategyHero from './components/strategy-hero'
import BookStrategy from './components/book-strategy'
import StrategyToolkit from './components/strategy-toolkit'
import Blog from './components/blog'

function ServicesStrategy({ data: { page, testimonials, posts } }) {
  return (
    <Layout>
      <SiteNav fullLogo />
      <StrategyHero page={page} />
      <BookStrategy />
      <StrategyToolkit />
      <Testimonials testimonials={testimonials} />
      <Blog posts={posts} />
      <Clients />
      <Customers />
      <Objectives />
      <AnnaQuote />
    </Layout>
  )
}

ServicesStrategy.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ServicesStrategy
