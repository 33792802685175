import React from 'react'
import { Section, Columns, Container } from 'react-bulma-components'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

// Components
import Separator from 'components/separator'

function BookStrategy() {
  return (
    <Section className="mt-0">
      <Container>
        <Columns className="is-centered">
          <Columns.Column className="is-5 is-offset-1">
            <h3 className="title">The Winning App Strategy</h3>
            <p>
              A look at the big decisions around your app, highlighting gaps in
              your offering and new opportunities.{' '}
              <span className="pill">£4,000</span>
            </p>
            <p>
              <Link to="/contact" className="">
                Contact us to learn more →
              </Link>
            </p>
          </Columns.Column>
          <Columns.Column className="is-5">
            <h3 className="title">The Opportunity Hunt</h3>
            <p>
              A deep audit of your app to identify big wins and quick wins,
              covering UX, marketing and tech.&nbsp;
              <span className="pill">£24,000</span>
            </p>
            <p>
              <Link to="/contact" className="">
                Contact us to learn more →
              </Link>
            </p>
          </Columns.Column>
          <Columns.Column className="is-5 is-offset-1 ">
            <h3 className="title">The Growth Baseline</h3>
            <p>
              An audit of your growth mechanics to help you put the foundations
              in place, including a KPI dashboard. &nbsp;
              <span className="pill">£4,000</span>
            </p>
            <p>
              <Link to="/contact" className="">
                Contact us to learn more →
              </Link>
            </p>
          </Columns.Column>
          <Columns.Column className="is-5">
            <h3 className="title">The Winning App Concept</h3>
            <p>
              A collaborative, human-centered process to design app concepts
              that drive maximum business growth.{' '}
              <span className="pill">£18,000</span>
            </p>
            <p>
              <Link to="/contact" className="">
                Contact us to learn more →
              </Link>
            </p>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}

export default BookStrategy
