import React from 'react'
import { Section, Columns, Container } from 'react-bulma-components'
import { StaticImage } from 'gatsby-plugin-image'

// Componnets
import Separator from 'components/separator'

function StrategyToolkit() {
  return (
    <Section>
      <Container>
        <Columns>
          <Columns.Column className="is-8 is-offset-2">
            <div className="card p-5 is-leafy">
              <Columns className="content">
                <Columns.Column className="is-4">
                  <StaticImage
                    src="https://www.datocms-assets.com/20955/1661010578-mobile-strategy-toolkit.png?fm=jpg&w=500"
                    alt="Mobile Strategy Toolkit - Pocketworks"
                    className="has-shadow"
                  />
                </Columns.Column>
                <Columns.Column className="is-8 pl-5">
                  <h3 className="is-size-3-mobile title">
                    Get the{' '}
                    <span className="has-text-success">
                      Mobile Tools &amp; Resources
                    </span>
                  </h3>
                  <p className="">
                    If you're looking to plan an app that has more impact, check
                    out this set of mobile strategy templates, downloads and
                    resources should help.{' '}
                  </p>
                  <a
                    href="/mobile-strategy-toolkit"
                    className="button is-leafy btn-primary"
                  >
                    View the toolkit
                  </a>
                </Columns.Column>
              </Columns>
            </div>
          </Columns.Column>
        </Columns>
        <Separator className="is-hidden-mobile mt-6 column" />
      </Container>
    </Section>
  )
}

export default StrategyToolkit
